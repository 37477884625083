// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-details-jsx": () => import("./../../../src/pages/about/details.jsx" /* webpackChunkName: "component---src-pages-about-details-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-about-mission-jsx": () => import("./../../../src/pages/about/mission.jsx" /* webpackChunkName: "component---src-pages-about-mission-jsx" */),
  "component---src-pages-academics-course-catalog-jsx": () => import("./../../../src/pages/academics/course-catalog.jsx" /* webpackChunkName: "component---src-pages-academics-course-catalog-jsx" */),
  "component---src-pages-career-faculty-recruitment-jsx": () => import("./../../../src/pages/career/faculty-recruitment.jsx" /* webpackChunkName: "component---src-pages-career-faculty-recruitment-jsx" */),
  "component---src-pages-career-project-staff-recruitment-jsx": () => import("./../../../src/pages/career/project-staff-recruitment.jsx" /* webpackChunkName: "component---src-pages-career-project-staff-recruitment-jsx" */),
  "component---src-pages-career-ta-recruitment-jsx": () => import("./../../../src/pages/career/ta-recruitment.jsx" /* webpackChunkName: "component---src-pages-career-ta-recruitment-jsx" */),
  "component---src-pages-career-tf-recruitment-jsx": () => import("./../../../src/pages/career/tf-recruitment.jsx" /* webpackChunkName: "component---src-pages-career-tf-recruitment-jsx" */),
  "component---src-pages-career-work-culture-jsx": () => import("./../../../src/pages/career/work-culture.jsx" /* webpackChunkName: "component---src-pages-career-work-culture-jsx" */),
  "component---src-pages-index-3-jsx": () => import("./../../../src/pages/index3.jsx" /* webpackChunkName: "component---src-pages-index-3-jsx" */),
  "component---src-pages-index-4-jsx": () => import("./../../../src/pages/index4.jsx" /* webpackChunkName: "component---src-pages-index-4-jsx" */),
  "component---src-pages-index-5-jsx": () => import("./../../../src/pages/index5.jsx" /* webpackChunkName: "component---src-pages-index-5-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-indexn-2-jsx": () => import("./../../../src/pages/indexn2.jsx" /* webpackChunkName: "component---src-pages-indexn-2-jsx" */),
  "component---src-pages-indexn-jsx": () => import("./../../../src/pages/indexn.jsx" /* webpackChunkName: "component---src-pages-indexn-jsx" */),
  "component---src-pages-news-and-events-calendar-jsx": () => import("./../../../src/pages/news-and-events/calendar.jsx" /* webpackChunkName: "component---src-pages-news-and-events-calendar-jsx" */),
  "component---src-pages-news-and-events-events-jsx": () => import("./../../../src/pages/news-and-events/events.jsx" /* webpackChunkName: "component---src-pages-news-and-events-events-jsx" */),
  "component---src-pages-news-and-events-media-coverage-jsx": () => import("./../../../src/pages/news-and-events/media-coverage.jsx" /* webpackChunkName: "component---src-pages-news-and-events-media-coverage-jsx" */),
  "component---src-pages-people-alumni-jsx": () => import("./../../../src/pages/people/alumni.jsx" /* webpackChunkName: "component---src-pages-people-alumni-jsx" */),
  "component---src-pages-people-btech-2-jsx": () => import("./../../../src/pages/people/btech2.jsx" /* webpackChunkName: "component---src-pages-people-btech-2-jsx" */),
  "component---src-pages-people-btech-jsx": () => import("./../../../src/pages/people/btech.jsx" /* webpackChunkName: "component---src-pages-people-btech-jsx" */),
  "component---src-pages-people-faculty-jsx": () => import("./../../../src/pages/people/faculty.jsx" /* webpackChunkName: "component---src-pages-people-faculty-jsx" */),
  "component---src-pages-people-mtech-jsx": () => import("./../../../src/pages/people/mtech.jsx" /* webpackChunkName: "component---src-pages-people-mtech-jsx" */),
  "component---src-pages-people-phd-jsx": () => import("./../../../src/pages/people/phd.jsx" /* webpackChunkName: "component---src-pages-people-phd-jsx" */),
  "component---src-pages-people-staff-2-jsx": () => import("./../../../src/pages/people/Staff2.jsx" /* webpackChunkName: "component---src-pages-people-staff-2-jsx" */),
  "component---src-pages-people-staff-jsx": () => import("./../../../src/pages/people/staff.jsx" /* webpackChunkName: "component---src-pages-people-staff-jsx" */),
  "component---src-pages-people-tf-jsx": () => import("./../../../src/pages/people/tf.jsx" /* webpackChunkName: "component---src-pages-people-tf-jsx" */),
  "component---src-pages-prospective-students-jsx": () => import("./../../../src/pages/prospective-students.jsx" /* webpackChunkName: "component---src-pages-prospective-students-jsx" */),
  "component---src-pages-research-labs-jsx": () => import("./../../../src/pages/research/labs.jsx" /* webpackChunkName: "component---src-pages-research-labs-jsx" */),
  "component---src-pages-research-patents-jsx": () => import("./../../../src/pages/research/patents.jsx" /* webpackChunkName: "component---src-pages-research-patents-jsx" */),
  "component---src-pages-research-projects-jsx": () => import("./../../../src/pages/research/projects.jsx" /* webpackChunkName: "component---src-pages-research-projects-jsx" */),
  "component---src-pages-research-publications-jsx": () => import("./../../../src/pages/research/publications.jsx" /* webpackChunkName: "component---src-pages-research-publications-jsx" */),
  "component---src-pages-research-publicationsn-2-jsx": () => import("./../../../src/pages/research/publicationsn2.jsx" /* webpackChunkName: "component---src-pages-research-publicationsn-2-jsx" */),
  "component---src-pages-research-pubs-2-jsx": () => import("./../../../src/pages/research/pubs2.jsx" /* webpackChunkName: "component---src-pages-research-pubs-2-jsx" */),
  "component---src-pages-research-pubs-3-jsx": () => import("./../../../src/pages/research/pubs3.jsx" /* webpackChunkName: "component---src-pages-research-pubs-3-jsx" */),
  "component---src-pages-research-spub-2-jsx": () => import("./../../../src/pages/research/spub2.jsx" /* webpackChunkName: "component---src-pages-research-spub-2-jsx" */),
  "component---src-pages-research-spub-jsx": () => import("./../../../src/pages/research/spub.jsx" /* webpackChunkName: "component---src-pages-research-spub-jsx" */),
  "component---src-pages-research-themes-jsx": () => import("./../../../src/pages/research/themes.jsx" /* webpackChunkName: "component---src-pages-research-themes-jsx" */),
  "component---src-templates-page-content-jsx": () => import("./../../../src/templates/PageContent.jsx" /* webpackChunkName: "component---src-templates-page-content-jsx" */)
}

